.af-signature-popup .MuiTab-root {
    border: 1px solid #D3D3D3 !important;
    border-radius: 7px;
    height: 37px;
    min-height: 37px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #64748b;
    margin-right: 13px;
    padding: 6px 10px !important;
    opacity: 0.7;
  }
  .af-signature-popup .MuiTab-root.Mui-selected {
    border: 1.5px solid #2563EB !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    color: #2563EB !important;
  }
  .af-signature-popup .MuiTabs-indicator {
    display: none;
}

.af-signature-popup input.signColorPicker {
    margin-bottom: 0px !important;
  }
  input[type="color"].signColorPicker {
      -webkit-appearance: none;
      border: none;
      width: 25px;
      height: 25px;
    padding: 0px;
    border-radius: 50px;
    margin-bottom: 0px !important;
  }
  input[type="color"].signColorPicker::-webkit-color-swatch-wrapper {
      border: none;
    border-radius: 50px;
    padding: 0;
  }
  input[type="color"].signColorPicker::-webkit-color-swatch {
      border: none;
    border-radius: 50px;
    padding: 0;
  }

  .upload-input-wrapper {
    width: 100%;
    position: relative;
  }
  .upload-input-wrapper input {
    width: 100%;
    height: 100px;
    opacity: 0;
    z-index: 1;
    position: relative;
    cursor: pointer;
}
  .upload-wrapper-ui {
    border: 1.5px dashed #2563EB !important;
    /* box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.17) !important; */
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 187px;
    top: 0px;
    left: 0px;
    cursor: pointer;
    /* background-color: #F8F9FF; */
  }

  .upload-wrapper-ui-text {
    font-size: 16px !important;
    font-weight: 600 !important;
    font-family: 'Wavehaus';
  }