.bouncing-dots {
    display: flex;
    justify-content: space-between;
    width: 34px;
}

.dot {
    width: 5px;
    height: 5px;
    background-color: #8360FD;
    border-radius: 50%;
    animation: bounceInfinity 1.5s infinite;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dot:nth-child(3) {
    animation-delay: 0.6s;
}

.dot:nth-child(4) {
    animation-delay: 0.9s;
}

@keyframes bounceInfinity {
    0%, 100% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-8px);
    }
}