.embed {
  width: fit-content;
  position: relative;
  margin: 0px;
}

.embed img {
  width: 100%;
}
.embed iframe {
  width: 100%;
  height: 100%;
}

.embed button.resize {
  position: absolute;
  bottom: 2px;
  right: 2px;
}

.embed-code {
  position: relative;
  margin: 0px;
}

.image-text-wrapper {
  padding: 0px;
  margin: 0px;
  left: 0px;
  color: #fff;
  font-weight: bold;
  display: flex;
  bottom: 0;
  width: 100%;
  flex-direction: column;
}

.image-text {
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0px;
  color: #fff;
  font-weight: bold;
}

.link-embed-wrapper iframe {
  width: 100%;
  height: 100%;
}

.link-embed-wrapper {
  width: 100%;
  height: 100%;
}

.link-embed-wrapper iframe {
  aspect-ratio: 16/9;
}

.link-embed-wrapper .deleteButton {
  background-color: white;
  /* box-shadow: 0px 0px 8px #ccc; */
  padding: 5px;
  width: 26px;
  height: 26px;
}

.link-embed-wrapper-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.link-embed-wrapper-container .docDeleteContainer {
  display: none;
}

.link-embed-wrapper-hover-container:hover .docDeleteContainer {
  display: block;
  position: absolute;
  top: -16px;
  right: 0;
  background-color: white;
}

.link-embed-wrapper-hover-container:hover .link-embed-wrapper {
  padding-top: 24px;
}

.editorDivider {
  border-width: 1px 0 0;
  border-color: rgba(55, 53, 47, .1607843137254902);
}
