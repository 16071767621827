.toolbar-wrapper {
    position: absolute;
    top: 0;
    /* background-color: #ffffff; */
    overflow: auto;
    display: flex;
    z-index: 2;
    width: 100%;
    padding: 12px 6px;
}

.editor-wrapper-toolbar-closed .toolbar-wrapper {
    background-color: unset;
    width: unset;
    right: unset;
}

.toolbar-toggle {
    display: flex;
    align-items: center;
    align-self: center;
    background-color: #ffffff;
    border-radius: 6px;
}

.editor-wrapper-toolbar-closed .toolbar-toggle {
    border-radius: 8px;
}

.toolbar-toggle .MuiTypography-body1 {
    font-size: 0.63rem;
}

.toolbar-wrapper.top-right,
.bottom-right {
    flex-direction: row-reverse;
    right: 0;
    left: 0
}

.top-left,
.bottom-left {
    flex-direction: row;
}

.left-top {
    flex-direction: column;
}

.left-bottom {
    flex-direction: column-reverse;
}

.top-left-toolbar-wrapper,
.bottom-left-toolbar-wrapper {
    left: 0;
    right: 0;
}

.left-top-toolbar-wrapper,
.left-bottom-toolbar-wrapper {
    left: 0;
    height: 100%;
    width: fit-content;
}

.right-top {
    flex-direction: column;
}

.right-bottom {
    flex-direction: column-reverse;
}

.right-top-toolbar-wrapper,
.right-bottom-toolbar-wrapper {
    right: 0;
    height: 100%;
    width: fit-content;
    transition: all 0.5s;
}

.left-top-toolbar-wrapper,
.left-bottom-toolbar-wrapper {
    left: 0;
    height: 100%;
    width: fit-content;
    transition: all 0.5s;
}

.bottom-left-toolbar-wrapper,
.bottom-right-toolbar-wrapper {
    top: unset;
    bottom: 0;
}
.toolbar-wrapper.bottom-right-toolbar-wrapper {
    right: 0 !important;
}

.toolbar {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.toolbar-grp1>* {
    /* margin-right: 10px; */
    cursor: pointer;
}

.toolbar-grp1 {
    margin: 0 2px;
    display: flex;
    align-items: center;
    row-gap: 12px;
}

.toolbar .toolbar-grp1:first-child .MuiOutlinedInput-root {
    margin-right: 10px;
}

.color-picker.popup-wrapper1 button {
    padding: 8px;
}

select {
    height: 30px;
    border: none;
    width: 6.9rem;
}

.toolbar .editor-dd {
    width: 200px;
}

.toolbar .MuiIconButton-root {}


::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    /* background: #E6E9F4; */
    background: #e6e9f48a;
}

/* Handle */
::-webkit-scrollbar-thumb {
    /* background: #bdc0d2; */
    background: #bdc0d240;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    /* background: #a1a5bd; */
    background: #bdc0d240;
}