
.color-options{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    align-items: center;
    gap: 10px;
}
.clicked{
  /* border: 1px solid lightgray; */
  border-bottom: none;
}
.option,.hexPreview{
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #000000;
  border: 1px solid #eee;
  flex-shrink: 0;
}
.color-picker form{
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: 100%;
}
.color-picker input{
  width: 65%;
  height: 30px !important;
  border:1px solid lightgray;
  border-radius: 5px !important;
  padding-left:5px
}
.color-picker button{
  margin:0;
  padding:0;
  cursor: pointer;
}
.color-picker input:focus{
  outline: none;
}
.color-picker-dialog .MuiPaper-root {
  padding: 20px;
}
.color-picker-dialog .popup {
  width: 100%;
  max-width: fit-content !important;
}
.backdrop {
  position: fixed;
  right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}
.colorSaveBtn {
  border-radius: 6px !important;
  height: 30px;
  padding: 0 15px !important;
  width: 60px !important;
  flex-shrink: 0;
}